import axios from "axios";

const API = axios.create({ baseURL: "https://chat.burundientempsreel.com" });

export const createChat = (data) => API.post("/chat/create", data);

export const userChats = (id) => API.get(`/chat/${id}`);

export const userGroupChats = (id) => API.get(`/chat/Group/${id}`);

export const findChat = (firstId, secondId) =>
  API.get(`/chat/find/${firstId}/${secondId}`);

export const createGroup = (data) => API.post("/chat/createGroup", data);
