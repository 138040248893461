import React, { useRef, useState } from "react";
import ChatBox from "../../components/ChatBox/ChatBox";
import GroupChatBox from "../../components/ChatBox/GroupChatBox";
import ChatSelect_User from "../../components/ChatBox/ChatSelect_User";
import ChatGroupSelect_User from "../../components/ChatBox/ChatGroupSelect_User";
import Conversation from "../../components/Coversation/Conversation";
import GroupConversation from "../../components/Coversation/GroupConversation";
import LogoSearch from "../../components/LogoSearch/LogoSearch";
import NavIcons from "../../components/NavIcons/NavIcons";
import "./Chat.css";
import { useEffect } from "react";
import { userChats, userGroupChats } from "../../api/ChatRequests";
import { newChat } from "../../api/ChatRequests";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import BroadChatBox from "../../components/ChatBox/BroadChatBox";

const Chat = () => {
  const dispatch = useDispatch();
  const socket = useRef();
  const { user } = useSelector((state) => state.authReducer.authData);
  const [chats, setChats] = useState([]);
  const [Groupchats, setGroupChats] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [currentGroupChat, setCurrentGroupChat] = useState(null);
  const [sendMessage, setSendMessage] = useState(null);
  const [receivedMessage, setReceivedMessage] = useState(null);
  const [newMessage, setnewMessage] = useState(false);
  const [newGroup, setnewGroup] = useState(false);
  const [currentWindow, setCurrentWindow] = useState("Chat");
  // Get the chat in chat section
  useEffect(() => {
    console.log(user);
    const getChats = async () => {
      try {
        const { data } = await userChats(user._id);
        setChats(data);
      } catch (error) {
        console.log(error);
      }
    };
    getChats();
  }, [user._id]);

  // Get the chat in chat section
  useEffect(() => {
    console.log(user);
    const getGroupChats = async () => {
      try {
        const { data } = await userGroupChats(user._id);
        setGroupChats(data);
      } catch (error) {
        console.log(error);
      }
    };
    getGroupChats();
  }, [user._id]);

  // Connect to Socket.io
  useEffect(() => {
    socket.current = io("https://socket.burundientempsreel.com");
    socket.current.emit("new-user-add", user._id);
    socket.current.on("get-users", (users) => {
      setOnlineUsers(users);
    });
  }, [user]);

  // Send Message to socket server
  useEffect(() => {
    if (sendMessage !== null) {
      socket.current.emit("send-message", sendMessage);
    }
  }, [sendMessage]);

  // Get the message from socket server
  useEffect(() => {
    socket.current.on("recieve-message", (data) => {
      console.log(data); 
      setReceivedMessage(data);
    });
  }, []);

  const checkOnlineStatus = (chat) => {
    const chatMember = chat.members.find((member) => member !== user._id);
    const online = onlineUsers.find((user) => user.userId === chatMember);
    return online ? true : false;
  };

  // Send Message
  const handlenewMessage = async (e) => {
    e.preventDefault();
    setnewMessage(true);
  };

  const handlenewGroup = async (e) => {
    e.preventDefault();
    setnewGroup(true);
  };

  return (
    <div className="Chat">
      {/* Left Side */}
      <div className="Left-side-chat">
        <LogoSearch />
        <div className="Chat-container">
          <div class="flex-chat-button">
            <h3>Chats</h3>{" "}
            <div className="new-button button" onClick={handlenewMessage}>
              New Message
            </div>
          </div>

          <div className="Chat-list">
            {chats.map((chat) => (
              <div
                onClick={() => {
                  setCurrentChat(chat);
                  setCurrentWindow("Chat");
                }}
              >
                <Conversation
                  data={chat}
                  currentUser={user._id}
                  online={checkOnlineStatus(chat)}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="Chat-container">
          <div class="flex-chat-button">
            <h3>Groupes</h3>{" "}
            <div className="new-button button" onClick={handlenewGroup}>
              New Group
            </div>
          </div>
          <div className="Chat-list">
            {Groupchats.map((chat) => (
              <div
                onClick={() => {
                  setCurrentGroupChat(chat);
                  setCurrentWindow("GroupChat");
                }}
              >
                <GroupConversation
                  data={chat}
                  currentUser={user._id}
                  online={checkOnlineStatus(chat)}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="Chat-container">
          <h3>BroadCast</h3>
          <div className="Chat-list">
            <div
              onClick={() => {
                setCurrentWindow("BroadCast");
              }}
            >
              <>
                <div className="follower conversation">
                  <div>
                    {<div className="online-dot"></div>}
                    <img
                      src={
                        process.env.REACT_APP_PUBLIC_FOLDER +
                        "defaultProfile.png"
                      }
                      alt="Profile"
                      className="followerImage"
                      style={{ width: "50px", height: "50px" }}
                    />
                    <div className="name" style={{ fontSize: "0.8rem" }}>
                      <span>BroadCast Messages</span>
                      <span style={{ color: "#51e200" }}>All Users</span>
                    </div>
                  </div>
                </div>
                <hr style={{ width: "85%", border: "0.1px solid #ececec" }} />
              </>
            </div>
          </div>
        </div>
      </div>

      {/* Right Side */}

      <div className="Right-side-chat">
        <div style={{ width: "20rem", alignSelf: "flex-end" }}>
          <NavIcons />
        </div>

        {newMessage ? (
          <>
            <ChatSelect_User />{" "}
          </>
        ) : newGroup ? (
          <>
            <ChatGroupSelect_User />
          </>
        ) : (
          <>
            {currentWindow === "Chat" ? (
              <ChatBox
                chat={currentChat}
                currentUser={user._id}
                setSendMessage={setSendMessage}
                receivedMessage={receivedMessage}
              />
            ) : currentWindow === "BroadCast" ? (
              <BroadChatBox
                chat={"currentChat"}
                currentUser={user._id}
                currentUsername={user.firstname + " " + user.lastname}
                setSendMessage={setSendMessage}
                receivedMessage={receivedMessage}
              />
            ) : currentWindow === "GroupChat" ? (
              <GroupChatBox
                chat={currentGroupChat}
                currentUser={user._id}
                setSendMessage={setSendMessage}
                receivedMessage={receivedMessage}
              />
            ) : null}
          </>
        )}
      </div>
      
    </div>
  );
};

export default Chat;
