import React, { useEffect, useState } from "react";
import "./ChatBox.css";
import FollowersModal from "../FollowersModal/FollowersModal";
import { getAllUser } from "../../api/UserRequests";
import User_Message from "../User/User_Message";
import { useSelector } from "react-redux";
const ChatBox = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const [persons, setPersons] = useState([]);
  const { user } = useSelector((state) => state.authReducer.authData);

  useEffect(() => {
    const fetchPersons = async () => {
      const { data } = await getAllUser();
      setPersons(data);
    };
    fetchPersons();
  }, []);
  return (
    <>
      <div className="ChatBox-container">
        <div className=" Select_User">
          <center>
            <h2>List Of People you may know</h2>
          </center>
          {persons.map((person, id) => {
            if (person._id !== user._id)
              return <User_Message person={person} key={id} />;
          })}
        </div>
      </div>
    </>
  );
};

export default ChatBox;
