import React from "react";

import Home from "../../img/home.png";
import Noti from "../../img/noti.png";
import Comment from "../../img/comment.png";
import { useDispatch, useSelector } from "react-redux";
import { UilSetting } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import { logout } from "../../actions/AuthActions";

const NavIcons = () => {
  const dispatch = useDispatch();
  const handleLogOut = () => {
    dispatch(logout());
  };
  return (
    <div className="navIcons">
      <Link to="../home">
        <img src={Home} alt="" />
      </Link>
      {/* <UilSetting />
      <img src={Noti} alt="" /> */}
      <Link to="../chat">
        <img src={Comment} alt="" />
      </Link>

      <div
        className="new-button button"
        onClick={handleLogOut}
        style={{ height: "40px" }}
      >
        Logout
      </div>
    </div>
  );
};

export default NavIcons;
