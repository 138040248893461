import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { followUser, unfollowUser } from "../../actions/UserAction";
import { createChat, createGroup } from "../../api/ChatRequests";
const User_Message = ({ person }) => {
  const publicFolder = process.env.REACT_APP_PUBLIC_FOLDER;
  const { user } = useSelector((state) => state.authReducer.authData);
  const dispatch = useDispatch();
  const [following, setFollowing] = useState(
    person.followers.includes(user._id)
  );

  const handleCreateMessage = async (id) => {
    const user_id = user._id;
    const CreatedChat = await createGroup({
      senderId: user_id,
      receiverId: id,
    });
    console.log("createdChat", CreatedChat);
  };
  console.log(user);
  return (
    <div
      className="follower "
      style={{
        borderRadius: "10px",
        padding: "10px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
        margin: "10px 0", // Adding margin of 5px to top and bottom
      }}
    >
      <div>
        <img
          src={
            publicFolder + person.profilePicture
              ? publicFolder + "defaultProfile.png"
              : publicFolder + "defaultProfile.png"
          }
          alt="profile"
          className="followerImage"
        />
        <div className="name">
          <span>{person.firstname}</span>
          <span>@{person.username}</span>
        </div>
      </div>
      <button
        className={"button fc-button"}
        onClick={() => handleCreateMessage(person._id)}
      >
        add to Group
      </button>
    </div>
  );
};

export default User_Message;
