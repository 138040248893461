import axios from "axios";

const API = axios.create({ baseURL: "https://chat.burundientempsreel.com" });

export const getMessages = (id) => API.get(`/message/${id}`);

export const addMessage = (data) => API.post("/message/", data);

export const addBroadMessage = (data) => API.post("/message/broadcast", data);

export const getBroadMessages = () => API.get(`/message/broadcast/messages`);
